import { taikoHekla, taikoMainnet } from '.';
import { hardhat } from 'viem/chains';
import { PUBLIC_ENVIRONMENT_STAGE } from '$env/static/public';
import { IEnvironment } from '../../types';

export const isCorrectNetwork = (networkId: number): boolean => {
  // if (window && (window as any).ethereum) {
  //   console.log({
  //     window: (window as any).ethereum?.networkVersion,
  //     networkId,
  //   });
  //   if (+(window as any).ethereum?.networkVersion !== networkId) {
  //     return false;
  //   }
  // }

  if (PUBLIC_ENVIRONMENT_STAGE === IEnvironment.LOCAL) return networkId === hardhat.id;
  if (PUBLIC_ENVIRONMENT_STAGE === IEnvironment.TESTNET) return networkId === taikoHekla.id;
  if (PUBLIC_ENVIRONMENT_STAGE === IEnvironment.STAGING) return networkId === taikoHekla.id;
  if (PUBLIC_ENVIRONMENT_STAGE === IEnvironment.PRODUCTION) return networkId === taikoMainnet.id;
  return false;
};

export const getExpectedNetworkId = () => {
  // if (PUBLIC_ENVIRONMENT_STAGE === IEnvironment.LOCAL) return hardhat.id;
  if (PUBLIC_ENVIRONMENT_STAGE === IEnvironment.TESTNET) return taikoHekla.id;
  if (PUBLIC_ENVIRONMENT_STAGE === IEnvironment.STAGING) return taikoHekla.id;
  if (PUBLIC_ENVIRONMENT_STAGE === IEnvironment.PRODUCTION) return taikoMainnet.id;
  return taikoMainnet.id;
};
